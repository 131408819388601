import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ReactAudioPlayer from "react-audio-player";

import { SetBlock } from '../components/SetBlock';
import { MixMeta } from "../models";
import { mixes } from "../audio";

import { MousePanel } from "../components/MousePanel";
import { Container } from "@material-ui/core";

enum PlayerState {
  Playing,
  Ready,
  Loading,
  Paused
}

export const UploadedMixes: Array<MixMeta> = [
  {
      Id: 1,
      Name: 'Hard Landing',
      Genre: 'Techno',
      MixType: 'Planet',
      Mood: 'Black',
      Obj: mixes.mix_1,
  },
  {
      Id: 2,
      Name: 'Strange Readings',
      Genre: 'Techno',
      MixType: 'Planet',
      Mood: 'Black',
      Obj: mixes.mix_2,
  },
  {
      Id: 3,
      Name: 'Warm Winds',
      Genre: 'Progressive',
      MixType: 'Planet',
      Mood: 'Black',
      Obj: mixes.mix_3,
  },
];

// markup
export function IndexPage(props) {
  const [selectedMix, setSelectedMix] = useState(null);
  const [playerState, setPlayerState] = useState(PlayerState.Loading);

  const select = (mix: MixMeta) => {
    setSelectedMix(mix);
    setPlayerState(PlayerState.Ready);
  }

  const unload = () => setSelectedMix(null);
  const pause = () => {}
  const play = () => {
    setPlayerState(PlayerState.Playing);
  }

  return (
    <main className="h-screen mr-16 ml-16 bg-black1 p-4 shadow-xl">
      <title>Home Page</title>
      <AnimatePresence>
        {selectedMix && (
          <motion.div
            initial={{height: 0}}
            animate={{height: '.2em'}}
            exit={{height: 0}}
            className="flex text-6xl pr-16 pl-16"
          >
            <ion-icon
              className="pr-4"
              name="caret-down-outline" 
              onClick={() => unload()}
            />
            <ReactAudioPlayer
              controls
              autoPlay
              className="flex w-full"
              src={selectedMix.Obj.default}
            />
            <ion-icon
              className="pl-4"
              name="list-outline"
            ></ion-icon>
          </motion.div>
        )}
      </AnimatePresence>
      
      <div className="pt-16">

        {/* Title Area */}
        <h1 className="text-5xl text-gray" >
          Digital Horizons
        </h1>
        <div className="h-px bg-green" />

        {/* Mixes */}
        <div className="p-4 flex flex-row flex-wrap">
          {UploadedMixes.map((mix: MixMeta) => 
            <SetBlock 
              mix={mix} 
              onClick={() => select(mix)}
              isSelected={mix.Id === selectedMix?.Id}
              key={mix.Id}
            />
          )}
        </div>


        {/* Title Area */}
        <h1 className="text-5xl text-gray" >
        Tracklists & More coming soon 🚀🚀🚀
        </h1>
        <div className="h-px bg-blue" />
      </div>

      {/* Bottom Area */}
      <div className="absolute bottom-4">
        <span>Made By Kaan</span>
        <span className="ml-8">
          <MousePanel panel={
            <>
              <div>email: <code>_nav@digitalhorizons.club</code></div>
              <div>twitter: @_navDJ</div>
            </>
          }>
            Contact
          </MousePanel>
        </span>
        <span className="ml-8">
          <MousePanel panel={
            <>
              <div>
                All credit goes to the rightful owners and producers.
                If you are the rightful owner please contact me and I will remove it immediately.
              </div>

              <div>
                Music & Tracklists are presented as promotional. Please support the artists!
              </div>
              <div>
                Playback is 129kbps to discourage ripping/slicing.
              </div>
            </>
          }>
            Rights
          </MousePanel>
        </span>
      </div>
    </main>
  )
}

export default IndexPage
