import React, { Component } from 'react';
import { motion } from 'framer-motion';
import { MixMeta, MixType, Genre } from '../models';

const COLLAPSED_WIDTH = '8rem';
const EXPANDED_WIDTH = '20rem';

interface SetBlockProps {
  mix: MixMeta;
  onClick: any;
  isSelected: boolean;
}

export function SetBlock(props) {
  let mixNameDisplay: string = '';
  if (props.isSelected) {
    // Type ID Name
    mixNameDisplay = `${MixType.GetLongName(props.mix?.MixType)} ${props.mix?.Id.toLocaleString('en', {minimumIntegerDigits:3})} ${props.mix?.Name}`
  } else {
    // TYPEID
    mixNameDisplay = `${MixType.GetShortName(props.mix?.MixType)}${props.mix?.Id.toLocaleString('en', {minimumIntegerDigits:3})}`
  }

  
  let genreColor: string = Genre.GetColor(props.mix?.Genre);
  const gradientColor = props.isSelected ? `bg-gradient-to-t from-black1 to-${genreColor}` : null;

  let MixIcon: JSX.Element = null;
  switch(props.mix.MixType) {
    case 'Planet': 
      MixIcon = <ion-icon name="planet-outline"/>;
    break;
    case 'Crew':
      MixIcon = <ion-icon name="finger-print-outline" />;
    break;
    case 'Analysis':
      MixIcon = <ion-icon name="rocket-outline" />
    break;
  }

    return (
      <motion.div 
        whileHover={{ scale: 1.1 }}
        initial={props.isSelected ? 'expanded' : 'collapsed'}
        variants={{
          expanded: () => ({
              width: EXPANDED_WIDTH,
          }),
          collapsed: () => ({
              width: COLLAPSED_WIDTH,
          })
      }}
        animate={props.isSelected ? 'expanded' : 'collapsed'}
        className={`flex h-32 w-32 bg-black1 rounded p-2 m-4 text-xl truncate border border-black0
        hover:border-${genreColor} hover:border ${gradientColor}`}
        key={`menu-${props.mix?.Id}`}
        onClick={props.onClick}
      >
        <div className="m-auto">
          <div className={genreColor}>
            <p className="text-center text-4xl">
              {MixIcon}
            </p>
          </div>
          <div className="">
            <p className="text-center font-mono">
              {mixNameDisplay} 
            </p>
          </div>
          <div >
            <p className="text-center">
              {props.mix.Genre}
            </p>
          </div>
        </div>
      </motion.div>
    )
} 