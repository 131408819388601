/** Metadata object for mixes */
export abstract class MixMeta {
    public Id: number;
    public Genre: GenreOptions;
    public MixType: MixTypes;
    public Mood: string;
    public Name: string;
    public Obj?: any;
}

export class Genre {
    public static Genres: GenreOptions;

    private static _map = {
        'Techno': {
            color: 'green'
        },
        'House': {
            color: 'blue'
        },
        'Progressive': {
            color: 'red'
        },
        'Trance': {
            color: 'pink'
        }
    }

    public static GetColor(type: GenreOptions) {
        return this._map[type].color;
    }
}

type GenreOptions = "Techno" | "House" | "Trance" | "Progressive";

/**
 * 
 */
export class MixType {
    private static _map = {
        'Planet': {
            short: 'PLNT',
            long: 'Planet'
        },
        'Crew': {
            short: 'CRW',
            long: 'Crew'
        },
        'Analysis': {
            short: 'ANLYZ',
            long: 'Analysis'
        }
    }

    public static Types: MixTypes;

    public static GetShortName(type: MixTypes) {
        return this._map[type].short;
    }

    public static GetLongName(type: MixTypes) {
        return this._map[type].long;
    }
}

type MixTypes = "Planet" | "Crew" | "Analysis";