import React from 'react';
import { Popover } from '@material-ui/core';
import { Paper } from '@material-ui/core';

export function MousePanel(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  let open = Boolean(anchorEl);

  return (
    <span 
        className="cursor-pointer ml-8"
        onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
        onClick={(e) => setAnchorEl(e.currentTarget)}
    >
      {props.children}
      <Popover
        id="mouse-over-popover"
        className="bg-gradient-to-t from-black1half to-black1"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
      >
        <Paper 
            elevation={2} 
            className="p-4" 
            onMouseLeave={(e) =>setAnchorEl(null)}
        >
            {props?.panel}
        </Paper>
      </Popover>
    </span>
    );
}
